import { ModifierDefinition } from "../data/ModifierDefinitions";
import { QuickAction } from "./QuickAction";


export interface Process {
	name: string;
	tag: string;
	type: string;
	id: string;
	isPublic: boolean;
	adminOnly: boolean;
	minVersion: number;
	modifiers: ModifierDefinition<any>[];
	quickActions : QuickAction[];
}

export function getProcessTitle(processID: string) : string {
	return `[process_${processID}_title]`;
}

export function getProcessDescription(processID: string): string {
	return `[process_${processID}_desc]`;
}

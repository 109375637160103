import MinMaxControl from "../controls/MinMaxControl";
import SliderControl from "../controls/SliderControl";
import ToggleGroupControl from "../controls/ToggleGroupControl";

import Box from '@mui/material/Box';
import { useEffect, useReducer, useState } from "react";
import ToggleControl from "../controls/ToggleControl";
import MultiSelectControl from "../controls/MultiSelectControl";
import { ModifierDefinition } from "../../../data/ModifierDefinitions";
import { getLatestSession } from "../../../adapters/Firebase";
import { Button, Divider, Typography } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Patient } from "../../../data/Patient";
import { useTranslation } from "../../../contexts/TranslationContext";


interface ProcessModifiersProps {
	modifierDefinitions: ModifierDefinition<any>[] | null;
	defaultModifiers?: any;
	onModifiersUpdated: (modifiers: any) => void;
	proccessID?: string;
	patient: Patient | null;
}

interface ProcessModifierData {
	id: string,
	value: string
}

function reducer(state: any, action: ProcessModifierData) {
	return { ...state, [action.id]: action.value };
}

function intToBoolArray(value: number, length: number): boolean[] {
	if (value === undefined) {
		return new Array(length).fill(true);
	}
	const result = new Array(length).fill(false);
	for (let i = 0; i < length; i++) {

		result[i] = (value & (1 << i)) !== 0;
	}
	return result;
}

function boolArrayToInt(value: boolean[]): number {
	let result = 0;
	for (let i = 0; i < value.length; i++) {
		if (value[i]) {
			result |= 1 << i;
		}
	}
	return result;
}

function textToInt(value: string): number {
	const result = value.split(' ').map((item) => item.toLowerCase() === "true");
	return boolArrayToInt(result);
}

function parseModifier(format: string, value: string) {
	switch (format.toLowerCase()) {
		case "minmax":
			return parseFloat(value);
		case "array":
		case "presets":
		case "togglegroup":
			return parseInt(value);
		case "bool":
			return value.toLowerCase() === "true";
		case "multiselect":
			return textToInt(value);
	}
}

function ProcessModifiers(props: ProcessModifiersProps) {

	const modifierDefinitions = props.modifierDefinitions;
	const { translate } = useTranslation();

	let defaultModifiers = modifierDefinitions?.reduce((acc, modifier) => {
		return { ...acc, [modifier.id]: modifier.default };
	}, {});

	const presets = modifierDefinitions?.find((modifier) => modifier.format.toLowerCase() === "presets");
	if (!!presets) {
		const defaultPreset = presets.default ?? 0;
		defaultModifiers = { ...defaultModifiers, ...presets.payload[defaultPreset].values };
	}

	if (!!props.defaultModifiers) {
		modifierDefinitions?.forEach((modifier) => {
			const key = modifier.id;
			const value = props.defaultModifiers?.[key];
			if (value !== undefined) {
				const castedValue = parseModifier(modifier.format, value.toString());
				defaultModifiers = { ...defaultModifiers, [key]: castedValue };
			}
		});
	}

	const [preset, setPreset] = useState<any>(defaultModifiers);
	const [modifiersState, dispatchModifiersState] = useReducer(reducer, {});
	const onModifiersUpdated = props.onModifiersUpdated;

	useEffect(() => {
		onModifiersUpdated(modifiersState);
	}, [modifiersState, onModifiersUpdated])

	useEffect(() => {
		modifierDefinitions?.filter((modifierF) => modifierF.isFixed)
			.forEach((modifier) => {
				dispatchModifiersState({ id: modifier.id, value: modifier.default.toString() });
			});
		modifierDefinitions?.filter((modifierF) => modifierF.format === "fixed")
			.forEach((modifier) => {
				dispatchModifiersState({ id: modifier.id, value: modifier.payload.toString() });
			});
	}, [modifierDefinitions])

	const handleLoadLatestModifiers = async () => {
		if (!props.proccessID || !props.patient) {
			return;
		}

		const processID = props.proccessID!;
		const patientID = props.patient.userID ?? props.patient.id;

		const session = await getLatestSession(patientID, processID);
		if (session) {
			const savedModifiers = session.modifiers;

			let presetModifications = {};
			modifierDefinitions?.forEach((modifier) => {
				const savedModifier = savedModifiers[modifier.id];
				if (savedModifier === undefined) {
					return;
				}
				const stringValue = savedModifier.toString();
				const value = parseModifier(modifier.format, stringValue);

				presetModifications = { ...presetModifications, [modifier.id]: value };
				dispatchModifiersState({ id: modifier.id, value: stringValue });
			});
			setPreset((prev: any) => { return { ...prev, ...presetModifications } });
		}
	}

	const presetModifier = modifierDefinitions?.find((modifier) => modifier.format.toLowerCase() === "presets");

	return (
		<>
			<Divider />

			<Typography marginY={2} variant="h6">
				{translate("[modifiers_title_basic]")}
			</Typography>

			{!!presetModifier &&
				<ToggleGroupControl
					key={presetModifier.title}
					items={presetModifier.payload.map((item: { title: any; }) => item.title)}
					value={preset[presetModifier.id]}
					onValueChanged={(value: number) => {
						const selectedItem = presetModifier.payload[value];
						setPreset((prev: any) => { return { ...prev, ...selectedItem.values, [presetModifier.id]: value } });
						dispatchModifiersState({ id: presetModifier.id, value: value.toString() });
					}} />
			}

			<Button
				onClick={handleLoadLatestModifiers}
				startIcon={<CloudDownloadIcon />}
				variant="outlined">
				{translate("[processespage_latestmodifiers]")}
			</Button>

			<br />
			<br />

			<Divider />

			<Typography marginY={2} variant="h6">
				{translate("[modifiers_title_advanced]")}
			</Typography>

			<Box display="flex" justifyContent="left" gap={0} flexDirection="column"  >
				{!!modifierDefinitions && modifierDefinitions
					.filter((modifier) => !modifier.isFixed && modifier.format !== "fixed")
					.sort((a, b) => a.order - b.order)
					.map((modifier) => {
						switch (modifier.format.toLowerCase()) {
							case "minmax": return <MinMaxControl
								key={modifier.title}
								min={modifier.payload.min}
								max={modifier.payload.max}
								step={modifier.payload.step}
								value={preset[modifier.id]}
								title={modifier.title}
								description={modifier.description}
								onValueChanged={(value: number) => {
									if (Number.isNaN(value)) {
										value = modifier.payload.min;
									}
									setPreset((prev: any) => { return { ...prev, [modifier.id]: value } });
									dispatchModifiersState({ id: modifier.id, value: value.toString() });
								}} />
							case "array": return <SliderControl
								key={modifier.title}
								min={0}
								max={modifier.payload.length - 1}
								value={preset[modifier.id]}
								title={modifier.title}
								description={modifier.description}
								values={modifier.payload}
								onValueChanged={(value: number) => {
									if (Number.isNaN(value)) {
										value = 0;
									}
									setPreset((prev: any) => { return { ...prev, [modifier.id]: value } });
									dispatchModifiersState({ id: modifier.id, value: value.toString() });
								}} />
							case "togglegroup": return <ToggleGroupControl
								key={modifier.title}
								items={modifier.payload}
								title={modifier.title}
								description={modifier.description}
								value={preset[modifier.id]}
								onValueChanged={(value: number) => {
									if (Number.isNaN(value)) {
										value = 0;
									}
									setPreset((prev: any) => { return { ...prev, [modifier.id]: value } });
									dispatchModifiersState({ id: modifier.id, value: value.toString() });
								}} />
							case "bool": return <ToggleControl
								key={modifier.title}
								title={modifier.title}
								description={modifier.description}
								value={!!preset[modifier.id]}
								onValueChanged={(value: boolean) => {
									if (Number.isNaN(value)) {
										value = false;
									}
									setPreset((prev: any) => { return { ...prev, [modifier.id]: value } });
									dispatchModifiersState({ id: modifier.id, value: value.toString() });
								}} />
							case "multiselect": return <MultiSelectControl
								key={modifier.title}
								items={modifier.payload}
								title={modifier.title}
								description={modifier.description}
								value={intToBoolArray(preset[modifier.id], modifier.payload.length)}
								onValueChanged={(value: boolean[]) => {
									setPreset((prev: any) => { return { ...prev, [modifier.id]: boolArrayToInt(value) } });
									dispatchModifiersState({ id: modifier.id, value: value.join(' ') });
								}} />
						}
						return null;
					})
				}
			</Box>
		</>
	)
}

export default ProcessModifiers;